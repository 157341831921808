import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import { useWindowSize } from "../../../Utilities";
import moment from "moment/moment";

import "./styles.css";
import { VehicleCatalog } from "shared";
import { FormInstance } from "antd/lib/form";
import {
  CkDatePicker,
  CkInput,
  CkInputNumber,
  CkSelect,
  CkSelectTime,
} from "../../../../CkUI/components/index";

interface IProps {
  form: FormInstance;
  handleHours: Function;
  availableHours: any[];
  getClosedDays: Function;
  getDataVehicleBrands: Function;
  vehicleTypes: VehicleCatalog[];
  isCustomBrand: boolean;
  getDataVehicleModels: Function;
  vehicleYears: VehicleCatalog[];
  isCustomModel: boolean;
  getDataVehicleFuelType: Function;
  vehicleModels: VehicleCatalog[];
  getDataVehicleBrandsYears: Function;
  setCustomBrand: Function;
  vehicleBrands: VehicleCatalog[];
  vehicleFuelType: VehicleCatalog[];
  customModel: any;
  setCustomModel: Function;
}

const fuelOptions = [
  {
    value: "DISEL",
    label: "Diesel",
  },
  {
    value: "ELECTRICO",
    label: "Eléctrico",
  },
  {
    value: "GASOLINA",
    label: "Gasolina",
  },
  {
    value: "HIBRIDO",
    label: "Híbrido",
  },
];

const AppointmentInformationForm: React.FC<IProps> = ({
  form,
  handleHours,
  availableHours,
  getClosedDays,
  getDataVehicleBrands,
  vehicleTypes,
  isCustomBrand,
  getDataVehicleModels,
  vehicleYears,
  isCustomModel,
  getDataVehicleFuelType,
  vehicleModels,
  getDataVehicleBrandsYears,
  vehicleBrands,
  setCustomBrand,
  vehicleFuelType,
  customModel,
  setCustomModel,
}) => {
  const size = useWindowSize();
  const [hoursOptions, setHoursOptions] = useState([]);

  useEffect(() => {
    const formattedAvailableHours = availableHours.map((hour, index) => {
      return {
        value: hour.replace(":", " "),
        label: hour,
      };
    });
    setHoursOptions(formattedAvailableHours);
  }, [availableHours]);

  return (
    <Form
      name="appointmentInformationForm"
      form={form}
      className="appointment-subform"
    >
      <Row gutter={[16, 0]}>
        {/*Date*/}
        <Col md={8} xs={24}>
          <CkDatePicker
            name="date"
            extra="*Fecha"
            // className="form-input-text"
            rules={[{ required: true, message: "Ingresa una fecha" }]}
            inputProps={{
              inputReadOnly: true,
              placeholder: "Selecciona la fecha",
              format: "yyyy-MM-DD",
              disabledDate: (date: any) =>
                getClosedDays().includes(date.day()) ||
                date <= moment().add(-7, "days").startOf("day"),
              onChange: (e) => handleHours(e),
              popupStyle: {
                zIndex: size.width > 992 ? 1110 : 2600,
              },
            }}
          />
        </Col>
        {/*Time*/}
        <Col md={8} xs={24}>
          <CkSelectTime
            name="datetime"
            extra="*Hora"
            // className="form-input-text"
            rules={[{ required: true, message: "Seleccione una hora" }]}
            inputProps={{
              placeholder: "Selecciona la hora",
              dropdownStyle: { zIndex: size.width > 992 ? 1110 : 2600 },
              options: hoursOptions,
              virtual: false,
            }}
          />
        </Col>
        {/*Type*/}
        <Col md={8} xs={24}>
          <CkSelect
            name={["vehicle", "typeCode"]}
            extra="*Tipo"
            // className="form-input-text"
            rules={[
              {
                required: true,
                message: "Ingresa el tipo del vehículo",
              },
            ]}
            inputProps={{
              placeholder: "Selecciona el tipo de vehículo",
              dropdownStyle: {
                zIndex: 5000,
              },
              onChange: (e) => getDataVehicleBrands(e),
              options:
                vehicleTypes &&
                vehicleTypes?.map((model: any, index) => {
                  return {
                    value: model.code,
                    label: model.desc,
                  };
                }),
            }}
          />
        </Col>
        {/*Marca*/}
        <Col md={8} xs={24}>
          <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
            <CkSelect
              name={["vehicle", "brandCode"]}
              extra="*Marca"
              // className="form-input-text"
              rules={[
                {
                  required: true,
                  message: "Ingresa la marca del vehículo",
                },
              ]}
              style={{
                width: isCustomBrand ? "50%" : "100%",
              }}
              inputProps={{
                // className: "input-bottom-border appo-form-item",
                placeholder: "Selecciona la marca del vehículo",
                dropdownStyle: { zIndex: 5000 },
                onChange: (e) => getDataVehicleBrandsYears(e),
                options: vehicleBrands?.map((model: any, index) => {
                  return {
                    value: model.code,
                    label: model.desc,
                  };
                }),
              }}
            />
            {isCustomBrand && (
              <CkInput
                name="customBrandName"
                extra=" "
                // className="form-input-text"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                  {
                    pattern: /^[\w&\-/\s]+$/,
                    message: "Símbolo no permitido",
                  },
                ]}
                style={{ width: "50%" }}
                inputProps={{
                  placeholder: "Marca",
                  // className: "input-bottom-border appo-form-item",
                  maxLength: 100,
                  onChange: (e) => setCustomBrand(e.target.value),
                }}
              />
            )}
          </div>
        </Col>
        {/*Año*/}
        <Col md={8} xs={24}>
          {isCustomBrand && (
            <CkInputNumber
              name={["vehicle", "yearCode"]}
              extra="*Año"
              // className="form-input-text"
              rules={[
                {
                  required: true,
                  message: "Ingresa el año del vehículo",
                },
                {
                  validator: (_, value) => {
                    if (value >= 1960 && value <= 9999) {
                      return Promise.resolve();
                    }
                    return Promise.reject("El año no puede ser menor a 1960");
                  },
                },
              ]}
              style={{ paddingTop: "2.5px" }}
              inputProps={{
                placeholder: "Selecciona el año del vehículo",
                // className: "input-bottom-border appo-form-item",
                minLength: 4,
                maxLength: 4,
                min: 1960,
                max: moment().year() + 1,
                onChange: (e) =>
                  form.setFields([
                    {
                      name: ["vehicle", "yearCode"],
                      value: e,
                    },
                  ]),
              }}
            />
          )}

          {!isCustomBrand && (
            <CkSelect
              name={["vehicle", "yearCode"]}
              extra="*Año"
              // className="form-input-text"
              rules={[
                {
                  required: true,
                  message: "Ingresa el año del vehículo",
                },
                {
                  validator: (_, value) => {
                    if (value >= 1960 && value <= 9999) {
                      return Promise.resolve();
                    }
                    return Promise.reject("El año no puede ser menor a 1960");
                  },
                },
              ]}
              inputProps={{
                dropdownStyle: { zIndex: 5000 },
                placeholder: "Selecciona el año del vehículo",
                onChange: (e) => getDataVehicleModels(e),
                options: vehicleYears?.map((year: any, index: number) => {
                  return {
                    value: year.code,
                    label: year.desc,
                  };
                }),
              }}
            />
          )}
        </Col>
        {/*Model*/}
        <Col md={8} xs={24}>
          <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
            {!isCustomModel && isCustomBrand && (
              <CkInput
                name={["vehicle", "modelCode"]}
                extra="*Modelo"
                // className="form-input-text"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el modelo del vehículo",
                  },
                  {
                    pattern: /^[\w&\-/\s]+$/,
                    message: "Símbolo no permitido",
                  },
                ]}
                style={{ flex: 1 }}
                inputProps={{
                  placeholder: "Ingresa el modelo del vehículo",
                  maxLength: 100,
                  onChange: (e) =>
                    form.setFields([
                      {
                        name: ["vehicle", "modelCode"],
                        value: e.target.value,
                      },
                    ]),
                }}
              />
            )}
            {!isCustomBrand && (
              <CkSelect
                name={["vehicle", "modelCode"]}
                extra="*Modelo"
                // className="form-input-text"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el modelo del vehículo",
                  },
                  {
                    pattern: /^[\w&\-/\s]+$/,
                    message: "Símbolo no permitido",
                  },
                ]}
                // style={{ width: isCustomModel ? "50%" : "100%" }}
                style={{ flex: 1 }}
                inputProps={{
                  placeholder: "Selecciona el modelo del vehículo",
                  dropdownStyle: {
                    zIndex: 5000,
                  },
                  onChange: (e) => getDataVehicleFuelType(e),
                  options: vehicleModels.map((model: any, index: any) => {
                    return {
                      value: model.code,
                      label: model.desc,
                    };
                  }),
                }}
              />
            )}

            {isCustomModel && (
              <CkInput
                name={["vehicle", "customModelCode"]}
                // className="form-input-text"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                  {
                    pattern: /^[\w&\-/]+$/,
                    message: "Símbolo no permitido",
                  },
                ]}
                style={{ flex: 1 }}
                initialValue={customModel}
                inputProps={{
                  // style: { width: "100%" },
                  placeholder: "Modelo",
                  defaultValue: customModel,
                  onBlur: (e) => setCustomModel(e.target.value),
                }}
              />
            )}
          </div>
        </Col>
        {/*FuelType*/}
        <Col md={8} xs={24}>
          <CkSelect
            name={["vehicle", "fuelTypeCode"]}
            extra="Tipo de combustible"
            inputProps={{
              placeholder: "Selecciona el tipo de combustible",
              dropdownStyle: {
                zIndex: 5000,
              },
              onChange: (e) =>
                form.setFields([
                  {
                    name: ["vehicle", "fuelTypeCode"],
                    value: e,
                  },
                ]),
              options:
                isCustomBrand || isCustomModel
                  ? fuelOptions
                  : vehicleFuelType.map((model: any, index: any) => {
                      return {
                        value: model.code,
                        label: model.desc,
                      };
                    }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(AppointmentInformationForm);
