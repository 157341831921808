import React from "react";
import { Col, Form, Input, Row } from "antd";
import { CkInput } from "../../../../CkUI/components";

import "./styles.css";

interface IProps {
  form: any;
}
const CustomerInformationForm: React.FC<IProps> = ({ form }) => (
  <Form name="customerInformationForm" form={form}>
    <Row>
      <h3
        style={{
          fontFamily: "Emprint-semibold",
          color: "var(--primary-color)",
          padding: "15px 0",
          fontSize: "24px",
        }}
      >
        Datos del cliente
      </h3>
    </Row>
    <Row gutter={[16, window.screen.width > 992 ? 8 : 0]}>
      <Col xl={8} xs={24}>
        <CkInput
          name={["endConsumer", "name"]}
          extra="*Nombre"
          rules={[
            {
              required: true,
              message: "Ingresa el nombre",
            },
            {
              pattern: new RegExp(/^[a-zA-Zá-úÁ-Ú0-9\s]+$/gi),
              message: "El campo no admite caracteres especiales",
            },
          ]}
          inputProps={{
            name: "clientName",
            placeholder: "Escribe el nombre del cliente",
          }}
        />
      </Col>
      <Col xl={8} xs={24}>
        <CkInput
          name={["endConsumer", "lastName"]}
          extra="*Apellido"
          rules={[
            {
              required: true,
              message: "Ingresa el apellido",
            },
            {
              pattern: new RegExp(/^[a-zA-Zá-úÁ-Ú0-9\s]+$/gi),
              message: "El campo no admite caracteres especiales",
            },
          ]}
          inputProps={{
            name: "clientLastName",
            placeholder: "Escribe el apellido del cliente",
          }}
        />
      </Col>
      <Col xl={8} xs={24}>
        <CkInput
          name={["endConsumer", "email"]}
          extra="*Correo electrónico"
          rules={[
            {
              required: true,
              message: "Ingresa el correo electrónico",
            },
            {
              type: "email",
              message: "Ingresa un correo electrónico",
            },
          ]}
          inputProps={{
            name: "email",
            type: "email",
            placeholder: "Escribe el correo electrónico del cliente",
          }}
        />
      </Col>
      <Col xl={8} xs={24}>
        <CkInput
          name={["endConsumer", "phone"]}
          extra="*Teléfono celular"
          rules={[
            {
              required: true,
              message: "Ingresa el teléfono del cliente",
            },
            {
              pattern: new RegExp(/^\d{10}$/gi),
              message: "El teléfono debe ser de 10 números",
            },
          ]}
          inputProps={{
            name: "phoneNumberClient",
            placeholder: "Escribe el celular del cliente",
          }}
        />
      </Col>
    </Row>
  </Form>
);

export default React.memo(CustomerInformationForm);
