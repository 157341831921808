import React, { FC, useEffect, useRef, useState } from "react";
import {
  IPackageCategory,
  IServiceCategory,
  WorkshopService,
  WorkshopServiceCase,
  WorkshopServiceTier,
  WorkshopServiceType,
} from "shared";
import "../styles.css";
import "./styles.css";
import { Form, Radio, RadioChangeEvent, Input, Collapse, Checkbox } from "antd";
import { FormInstance } from "antd/lib/form";
import {
  CkMessage,
  CkSwitchText,
  CkIcon,
  CkIconNames,
  CkButton,
} from "../../../../../CkUI";
import { useSelector } from "react-redux";
import {
  getPackagesCategories,
  getServicesCategories,
  getWorkshopServices,
} from "../../../../../stores";
import { translatePathToIcon } from "shared";
import { parseCurrency } from "../../../../Utilities";
import { debounce2 } from "../../../../Utilities";

const DEBOUNCE_DELAY = 200; // You can set the delay as desired
const { TextArea } = Input;

interface IProps {
  form: FormInstance;
}
const PackageServiceSelection: React.FC<IProps> = ({ form }) => {
  const workshopServices = useSelector(getWorkshopServices);
  const packagesCategories = useSelector(getPackagesCategories);
  const servicesCategories = useSelector(getServicesCategories);
  const [selectedType, setSelectedType] = useState<WorkshopServiceType>("MAN"); // Service type - True: "services", False: "packages";
  const [selectedCategory, setSelectedCategory] = useState<
    IPackageCategory | IServiceCategory
  >();
  const [selectedService, setSelectedService] = useState<WorkshopService>();
  const [selectedServiceTier, setSelectedServiceTier] =
    useState<WorkshopServiceTier>();
  const [availablePackagesCategories, setAvailablePackagesCategories] =
    useState<IPackageCategory[]>([]);
  const [availableServicesCategories, setAvailableServicesCategories] =
    useState<IServiceCategory[]>([]);
  const [availablePackages, setAvailablePackages] = useState<WorkshopService[]>(
    []
  );
  const [availableServices, setAvailableServices] = useState<WorkshopService[]>(
    []
  );
  const [selectedFailures, setSelectedFailures] = useState<
    WorkshopServiceCase[]
  >([]);

  const serviceItem = useRef<{
    services: any;
    serviceName: string;
    failures: any[];
    typedFailure: string;
    packages: any;
    packageTier: any;
  }>({
    services: undefined,
    serviceName: "",
    failures: [],
    typedFailure: "",
    packages: undefined,
    packageTier: undefined,
  });
  /**
   * States
   */
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  /**
   * Functions
   */

  const validateForm = async (...args) => {
    const [_, values] = args;
    // @ts-ignore
    const fields = values ? values : form.getFieldsValue();

    // Check type
    if (fields["service-type"] !== "REP" && fields["service-type"] !== "MAN") {
      isValidForm && setIsValidForm(false);
      return false;
    }

    // Check category
    if (!!fields["service-category"] === false) {
      isValidForm && setIsValidForm(false);
      return false;
    }

    // Check maintenance
    if (
      fields["service-type"] === "MAN" &&
      !!fields["service-maintenance"] === false
    ) {
      isValidForm && setIsValidForm(false);
      return false;
    }

    // Check tier
    if (
      fields["service-type"] === "MAN" &&
      !!fields["service-maintenance-tier"] === false
    ) {
      isValidForm && setIsValidForm(false);
      return false;
    }

    // Check repair
    if (
      fields["service-type"] === "REP" &&
      !!fields["service-repair"] === false
    ) {
      isValidForm && setIsValidForm(false);
      return false;
    }

    // Check cases
    const validCases =
      Array.isArray(fields["service-cases"]) &&
      fields["service-cases"].length > 0;
    if (
      fields["service-type"] === "REP" &&
      validCases === false &&
      !!fields["service-case-extra"] === false
    ) {
      isValidForm && setIsValidForm(false);
      return false;
    }

    // Check datetime
    if (fields.date === undefined || fields.datetime === undefined) {
      console.error("Debe indicar la fecha y hora del servicio");
      setIsValidForm(false);
      return false;
    }
    setIsValidForm(true);
  };

  const debouncedCheckChanges = debounce2(validateForm, DEBOUNCE_DELAY);

  const handleCriteriaChange = (newValue: boolean) => {
    const value = newValue ? "REP" : "MAN";
    if (value === "REP" && availableServices.length === 0) {
      CkMessage({
        type: "error",
        text: "Este taller no posee ningun servicio de reparación",
      });
      // @ts-ignore
      form.setFieldsValue({
        service: {
          "service-type": "MAN",
        },
      });
      setSelectedType("MAN");
      return false;
    }
    if (value === "MAN" && availablePackages.length === 0) {
      CkMessage({
        type: "error",
        text: "Este taller no posee ningun mantenimiento",
      });
      // @ts-ignore
      form.setFieldsValue({
        service: {
          "service-type": "REP",
        },
      });
      setSelectedType("REP");
      return false;
    }

    setSelectedType(value);
    setSelectedCategory(undefined);
    setSelectedService(undefined);
    setSelectedServiceTier(undefined);
    setSelectedFailures([]);

    // @ts-ignore
    form.setFieldsValue({
      service: {
        "service-type": value,
        "service-category": undefined,
        "service-maintenance": undefined,
        "service-maintenance-tier": undefined,
        "service-repair": undefined,
        "service-cases": [],
        "service-case-extra": undefined,
      },
    });

    validateForm(undefined, form.getFieldsValue());
  };

  const onCategoryChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    let objectValue;
    if (selectedType === "MAN") {
      objectValue = packagesCategories.find(
        (pack) => pack.servicePackageCode === value
      );
    } else {
      objectValue = servicesCategories.find(
        (service) => service.serviceTypeCode === value
      );
    }
    setSelectedCategory(objectValue);
    setSelectedService(undefined);
    setSelectedServiceTier(undefined);
    setSelectedFailures([]);
    // @ts-ignore
    form.setFieldsValue({
      service: {
        "service-maintenance": undefined,
        "service-maintenance-tier": undefined,
        "service-repair": undefined,
        "service-cases": [],
        "service-case-extra": undefined,
      },
    });
  };

  const onMaintainanceServiceChange = (e: RadioChangeEvent) => {
    const objectValue = availablePackages.find(
      (pack) => pack.workshopServiceId === e.target.value
    );
    setSelectedService(objectValue);
    setSelectedServiceTier(undefined);
    setSelectedFailures([]);
    form.setFieldsValue({
      service: {
        "service-maintenance-tier": undefined,
        "service-cases": [],
        "service-case-extra": undefined,
      },
    });
  };

  const onMaintainanceTierChange = (e: RadioChangeEvent) => {
    const objectValue = selectedService.serviceTiers.find(
      (pack) => pack.servicePackageTypeCode === e.target.value
    );
    setSelectedServiceTier(objectValue);
    validateForm(undefined, form.getFieldsValue());
  };

  const onRepairServiceChange = (e: RadioChangeEvent) => {
    const objectValue = availableServices.find(
      (pack) => pack.workshopServiceId === e.target.value
    );
    setSelectedService(objectValue);
    setSelectedServiceTier(undefined);
    setSelectedFailures([]);
    // @ts-ignore
    form.setFieldsValue({
      service: {
        "service-maintenance-tier": undefined,
        "service-cases": [],
        "service-case-extra": undefined,
      },
    });
  };

  const onClearSelectedService = () => {
    setSelectedService(undefined);
    setSelectedServiceTier(undefined);
    setSelectedFailures([]);
    // @ts-ignore
    form.setFieldsValue({
      service: {
        "service-maintenance": undefined,
        "service-maintenance-tier": undefined,
        "service-repair": undefined,
        "service-cases": [],
        "service-case-extra": undefined,
      },
    });
  };

  const toggleSelectedFailure = (failure: WorkshopServiceCase) => {
    const isSelected = selectedFailures.some(
      (item) =>
        item.workshopCustomServicesCaseId ===
        failure.workshopCustomServicesCaseId
    );
    if (isSelected) {
      const newArray = selectedFailures.filter(
        (item) =>
          item.workshopCustomServicesCaseId !==
          failure.workshopCustomServicesCaseId
      );
      setSelectedFailures(newArray);
      serviceItem.current.failures = newArray;
    } else {
      if (selectedFailures?.length === 3) return false;
      const newArray = [...selectedFailures, failure];
      setSelectedFailures(newArray);
      serviceItem.current.failures = newArray;
    }
  };

  /**
   * Effects
   */

  useEffect(() => {
    if (workshopServices) {
      const _availablePackages = workshopServices.filter(
        (service) => service.workshopServiceType === "MAN"
      );
      const _availableServices = workshopServices.filter(
        (service) => service.workshopServiceType === "REP"
      );
      setAvailablePackages(_availablePackages);
      setAvailableServices(_availableServices);
    }
  }, [workshopServices]);

  useEffect(() => {
    if (availablePackages.length > 0) {
      const availableCategories = packagesCategories.filter((packageCategory) =>
        availablePackages.some(
          (pack) =>
            pack.servicePackageCode === packageCategory.servicePackageCode
        )
      );
      setAvailablePackagesCategories(availableCategories);
    }
  }, [availablePackages, packagesCategories]);

  useEffect(() => {
    if (availableServices.length > 0) {
      const availableCategories = servicesCategories.filter((serviceCategory) =>
        availableServices.some(
          (service) => service.srvcTypeCode === serviceCategory.serviceTypeCode
        )
      );
      setAvailableServicesCategories(availableCategories);
    }
  }, [availableServices, servicesCategories]);

  /**
   * Components
   */
  const CategoryServiceCard: FC<{
    type: "package" | "service";
    icon: string;
    label: string;
  }> = ({ type, icon, label }) => (
    <div className="category-service-card">
      <CkIcon name={translatePathToIcon(icon, type, () => {}) as CkIconNames} />
      <p>{label}</p>
    </div>
  );

  const CategorySelection = () => (
    <div
      className={[
        "selector-service-category",
        ...(selectedCategory ? ["selected"] : []),
      ].join(" ")}
    >
      <p>*La cita es para un servicio de:</p>
      {selectedCategory !== undefined && (
        <div>
          <CategoryServiceCard
            type={selectedType === "MAN" ? "package" : "service"}
            label={
              selectedType === "MAN"
                ? (selectedCategory as IPackageCategory).servicePackageDesc
                : (selectedCategory as IServiceCategory).serviceTypeName
            }
            icon={selectedCategory.iconPath}
          />
          <CkButton
            variant="link"
            block={false}
            onClick={() => {
              setSelectedCategory(undefined);
              setSelectedService(undefined);
              setSelectedServiceTier(undefined);
              setSelectedFailures([]);
              form.setFieldValue("service-category", undefined);
              form.setFieldValue("service-maintenance", undefined);
              form.setFieldValue("service-maintenance-tier", undefined);
              form.setFieldValue("service-repair", undefined);
              form.setFieldValue("service-cases", undefined);
            }}
          >
            Cambiar
          </CkButton>
        </div>
      )}
      <Form.Item name={["service", "service-type"]} className="service-type">
        <CkSwitchText
          name="searchBySwitch"
          className={[
            "search-by-switch",
            ...(selectedType === "REP" ? ["checked"] : [""]),
          ].join(" ")}
          leftText="Mantenimiento"
          rightText="Reparación"
          onChange={handleCriteriaChange}
          checked={selectedType === "REP"}
          aria-label="¿Qué tipo de servicio estás buscando?"
        />
      </Form.Item>
      <Form.Item
        name={["service", "service-category"]}
        className={`service-category`}
      >
        <Radio.Group onChange={onCategoryChange} value={selectedCategory}>
          {selectedType === "MAN" &&
            availablePackagesCategories.map((pack) => (
              <Radio
                key={pack.servicePackageCode}
                value={pack.servicePackageCode}
              >
                <CategoryServiceCard
                  type="package"
                  label={pack.servicePackageDesc}
                  icon={pack.iconPath}
                />
              </Radio>
            ))}
          {selectedType === "REP" &&
            availableServicesCategories.map((service) => (
              <Radio
                key={service.serviceTypeCode}
                value={service.serviceTypeCode}
              >
                <CategoryServiceCard
                  type="service"
                  label={service.serviceTypeName}
                  icon={service.iconPath}
                />
              </Radio>
            ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );

  const MaintainanceSelection = () => (
    <div
      className={[
        "selector-service-maintenance",
        ...(selectedService ? ["selected"] : []),
      ].join(" ")}
    >
      <p>Selecciona el servicio a realizar:</p>
      <Form.Item
        name={["service", "service-maintenance"]}
        className={`service-maintenance`}
      >
        <Radio.Group
          onChange={onMaintainanceServiceChange}
          value={selectedService}
        >
          {availablePackages
            .filter(
              (pack) =>
                pack.servicePackageCode ===
                (selectedCategory as IPackageCategory).servicePackageCode
            )
            .map((pack) => (
              <Radio
                key={pack.workshopServiceId}
                value={pack.workshopServiceId}
              >
                {pack.workshopServiceName}
              </Radio>
            ))}
        </Radio.Group>
      </Form.Item>
      {selectedService && (
        <div className="selected-service">
          <p>{selectedService.workshopServiceName}</p>
          <CkButton
            icon="close"
            shape="circle"
            type="text"
            onClick={onClearSelectedService}
          />
        </div>
      )}
    </div>
  );

  const MaintainanceTierSelection = () => (
    <div
      className={[
        "selector-service-maintenance-tier",
        ...(selectedServiceTier ? ["selected"] : []),
      ].join(" ")}
    >
      <Form.Item
        name={["service", "service-maintenance-tier"]}
        className={`service-maintenance-tier`}
      >
        <Radio.Group
          onChange={onMaintainanceTierChange}
          value={selectedServiceTier}
        >
          {selectedService.serviceTiers.map((tier) => (
            <Radio
              key={tier.servicePackageTypeCode}
              value={tier.servicePackageTypeCode}
            >
              {tier.servicePackageTypeDsc}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      {selectedServiceTier && (
        <div className="tier-details">
          <p className="details">
            {selectedServiceTier.servicePackageTypeDetails}
          </p>
          {!!selectedServiceTier.servicePackageTypeCodeBasePrice && (
            <div className="price-container">
              <p>Precio base:</p>
              <p>
                {parseCurrency(
                  selectedServiceTier.servicePackageTypeCodeBasePrice
                )}
                <sup>*</sup> <span>incluye IVA</span>
              </p>
            </div>
          )}
          {!!selectedServiceTier.servicePackageTypeCodeBasePrice === false && (
            <p className="no-price">
              El precio base de diagnóstico de este servicio esta sujeto a
              cotización.
            </p>
          )}
          {selectedServiceTier.servicePackageTypeComments && (
            <p className="comments">
              {selectedServiceTier.servicePackageTypeComments}
            </p>
          )}
        </div>
      )}
    </div>
  );

  const RepairSelection = () => (
    <div
      className={[
        "selector-service-repair",
        ...(selectedService ? ["selected"] : []),
      ].join(" ")}
    >
      <p>Selecciona el servicio a realizar:</p>
      <Form.Item
        name={["service", "service-repair"]}
        className={`service-repair`}
      >
        <Radio.Group onChange={onRepairServiceChange} value={selectedService}>
          {availableServices
            .filter(
              (service) =>
                service.srvcTypeCode ===
                (selectedCategory as IServiceCategory).serviceTypeCode
            )
            .map((service) => (
              <Radio
                key={service.workshopServiceId}
                value={service.workshopServiceId}
              >
                {service.workshopServiceName}
              </Radio>
            ))}
        </Radio.Group>
      </Form.Item>
      {selectedService && (
        <div className="selected-service">
          <p>{selectedService.workshopServiceName}</p>
          <CkButton
            icon="close"
            shape="circle"
            type="text"
            onClick={onClearSelectedService}
          />
        </div>
      )}
    </div>
  );

  const RepairDetailsSelection = () => (
    <div className={"selector-service-repair-details"}>
      {selectedService.serviceCases &&
        selectedService.serviceCases.length > 0 && (
          <Form.Item
            name={["service", "service-cases"]}
            className={`service-cases`}
          >
            <Checkbox.Group>
              {selectedService.serviceCases.map((serviceCase) => (
                <Checkbox
                  className="--alter --orange"
                  key={`service-case-${serviceCase.workshopCustomServicesCaseId}`}
                  value={serviceCase}
                  checked={selectedFailures.some(
                    (item) => item.workshopCustomServicesCaseId === serviceCase.workshopCustomServicesCaseId
                  )}
                  disabled={
                    !selectedFailures.some((item) => item.workshopCustomServicesCaseId === serviceCase.workshopCustomServicesCaseId) &&
                    selectedFailures.length >= 3
                  }
                  onClick={() => toggleSelectedFailure(serviceCase)}
                >
                  {serviceCase.serviceCaseDesc}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
      <Form.Item
        label="Si tu falla no está en la lista, descríbela aquí:"
        name={["service", "service-case-extra"]}
        className="service-case-extra"
        help="El mensaje debe tener un mínimo de 30 caracteres y un máximo de 300."
        rules={[
          {
            pattern: /^.{30,300}$/,
            message:
              "El mensaje debe tener un mínimo de 30 caracteres y un máximo de 300.",
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      {!!selectedService.basePrice && (
        <div className="price-container">
          <p>Precio base:</p>
          <p>
            {parseCurrency(selectedService.basePrice)}
            <sup>*</sup> <span>incluye IVA</span>
          </p>
        </div>
      )}
      {!!selectedService.basePrice === false && (
        <p className="no-price">
          El precio base de diagnóstico de este servicio esta sujeto a
          cotización.
        </p>
      )}
      {selectedService.serviceComments && (
        <p className="comments">{selectedService.serviceComments}</p>
      )}
    </div>
  );

  return (
    <Form
      form={form}
      name="packageServiceSelectionForm"
      className="package-service-selection-form appointment-subform"
      onValuesChange={debouncedCheckChanges}
      initialValues={{
        service: {
          "service-type": "MAN",
        },
      }}
    >
      {CategorySelection()}
      {selectedType === "MAN" && selectedCategory && MaintainanceSelection()}
      {selectedType === "MAN" &&
        selectedCategory &&
        selectedService &&
        MaintainanceTierSelection()}
      {selectedType === "REP" && selectedCategory && RepairSelection()}
      {selectedType === "REP" &&
        selectedCategory &&
        selectedService &&
        RepairDetailsSelection()}
    </Form>
  );
};

export default React.memo(PackageServiceSelection);
