import React, { useEffect, useState } from "react";
import { Col, Form, message, Row } from "antd";
import "./styles.css";
import AppointmentInformationForm from "./AppointmentInformationForm";
import {
  Items,
  WorkHours,
  WorkshopService,
  WorkshopServiceCase,
  WorkshopServiceTier,
} from "shared/src/data-models/Workshop";
import moment from "moment/moment";
import CustomerInformationForm from "./CustomerInformationForm";
import PackageServiceSelection from "./PackageServiceSelection";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import { addAppointment, VehicleCatalog } from "shared";
import { getCurrentPosition, gtmAddLayer } from "../../../Utilities";
import { IPositionResponse } from "../../../Utilities/Position";
import { useDispatch } from "react-redux";
import { CurrentUser } from "../../../../context/UserContext";
import { useHistory } from "react-router-dom";
import { ProductRecommendationModal } from "../../Modals/ProductRecommendationModal";
import { CatalogAPI as CatalogsAPI } from "../../../Services/axios";
import { setCurrentSection } from "../../../../stores";
import { CkButton, CkMessage } from "../../../../CkUI";

interface Product {
  productName: string;
  viscosity: string;
  oilType: string;
  mainBenefit: string;
  secondaryBenefit: string;
  productDesc: string;
  photoUrl: string;
  enabled: boolean;
  productRecommendationLinks: string[] | undefined;
}

interface VehicleData {
  Nickname: string;
  TypeCode: string;
  TypeName: string;
  BrandCode: string;
  BrandName: string;
  IsCustomBrand: boolean;
  YearCode: string;
  Year: string;
  ModelCode: string;
  ModelName: string;
  FuelTypeCode: string;
  FuelTypeName: string;
  IsCustomModel: boolean;
  IsEnabled: boolean;
  Km: string | number;
  LicensePlate: string;
  VehicleId: number | "" | null;
}

interface ProductLink {
  icon: string;
  url: string;
}

interface IProps {
  hours: WorkHours[];
  workshopServices: WorkshopService[];
  workshopPackages: Items[];
  workshop: any;
  workshopId: string;
}
const NewAppointmentForm: React.FC<IProps> = ({
  hours,
  workshopServices,
  workshopPackages,
  workshop,
  workshopId,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const user = React.useContext(CurrentUser);
  const [vehicleCategorySelected, setVehicleCategorySelected] =
    useState<string>("");

  const [workingHours, setWorkingHours] = useState<any[]>([]);
  const [availableHours, setAvailableHours] = useState<string[]>([]);

  const [vehicleTypes, setVehicleTypes] = useState<VehicleCatalog[]>([]);
  const [vehicleBrands, setVehicleBrands] = useState<VehicleCatalog[]>([]);
  const [vehicleYearsN, setVehicleYearsN] = useState<VehicleCatalog[]>([]);
  const [vehicleModels, setVehicleModels] = useState<VehicleCatalog[]>([]);
  const [vehicleFuelType, setVehicleFuelType] = useState<VehicleCatalog[]>([]);

  const [isCustomBrand, setIsCustomBrand] = React.useState<boolean>(false);
  const [customBrand, setCustomBrand] = React.useState<string>("");
  const [isCustomModel, setIsCustomModel] = React.useState<boolean>(false);
  const [customModel, setCustomModel] = React.useState<string>("");

  const [criteria, setCriteria] = useState<"packages" | "services">("packages");

  const [selectedPackage, setSelectedPackage] = useState<WorkshopService>();
  const [selectedPackageTier, setSelectedPackageTier] =
    useState<WorkshopServiceTier>(undefined);
  const [selectedServices, setSelectedServices] = useState<any>();
  const [selectedFailures, setSelectedFailures] = useState<
    {
      serviceName: string;
      serviceCategoryCode: string;
      serviceCategoryName: string;
      services: string[];
    }[]
  >([]);
  const [processing, setProcessing] = useState<boolean>(false);
  // Recommendations modal info
  const [recommendedProduct, setRecommendedProduct] = useState<any[]>([]);
  const [showRecProduct, setShowRecProduct] = useState<boolean>(false);
  const [recProductQuantity, setRecProductQuantity] = useState<number>(0);
  const [servicesForRec, setServicesForRec] = useState<string>("");

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const getProductRecommendations = async (
    vehicleId: string,
    servicesCodeArray: string,
    endConsumerId: string,
    servicesPackageList: string
  ) => {
    let products: Array<Product> = [];
    const recProductsRes = await CatalogsAPI.getRecommendedProducts(
      vehicleId,
      servicesCodeArray,
      endConsumerId,
      servicesPackageList
    );
    recProductsRes.data.map((res) =>
      products.push({
        productName: res.productName,
        viscosity:
          res.viscosity === "null" || res.viscosity == null
            ? ""
            : res.viscosity,
        oilType: res.oilType,
        mainBenefit: res.mainBenefit,
        secondaryBenefit: res.secondaryBenefit,
        productDesc: res.productDesc,
        photoUrl: res.photoUrl,
        enabled: true,
        productRecommendationLinks: res.productRecommendationLinks,
      })
    );
    return recProductsRes;
  };

  const handleErrorsForConfirm = (
    error: string,
    loadingMessageKey?: string
  ) => {
    if (
      error === "Request failed with status code 400" ||
      error === "Request failed with status code 500"
    ) {
      // debugger;
      CkMessage({
        type: "error",
        text: "Ocurrió un error al crear la cita",
        ...(loadingMessageKey ? { messageToDestroy: loadingMessageKey } : {}),
      });
    } else if (error === "Request failed with status code 418") {
      CkMessage({
        type: "error",
        text: "Este correo esta asociado a un taller",
        ...(loadingMessageKey ? { messageToDestroy: loadingMessageKey } : {}),
      });
    } else if (error === "Request failed with status code 404") {
      CkMessage({
        type: "error",
        text: "El usuario se encuentra inhabilitado, por favor contacta a servicio al cliente para mas detalles.",
        ...(loadingMessageKey ? { messageToDestroy: loadingMessageKey } : {}),
      });
    } else if (error === "Request failed with status code 409") {
      CkMessage({
        type: "error",
        text: "El kilometraje no puede ser menor al registrado actualmente. Tenga en cuenta que cuando se registra por primera vez, se debe verificar el dato, para poder brindar recomendaciones de servicios de mantenimiento preventivo.",
        ...(loadingMessageKey ? { messageToDestroy: loadingMessageKey } : {}),
      });
    } else if (error === "Request failed with status code 494") {
      CkMessage({
        type: "error",
        text: "No es posible agendar la cita ya que ya tiene una cita en otro taller el día seleccionado cerca de la hora asignada.",
        ...(loadingMessageKey ? { messageToDestroy: loadingMessageKey } : {}),
      });
    } else if (error === "Request failed with status code 495") {
      CkMessage({
        type: "error",
        text: "No es posible agendar la cita ya que ya tiene una cita en tu taller, a la hora seleccionada y con servicios o paquetes duplicados.",
        ...(loadingMessageKey ? { messageToDestroy: loadingMessageKey } : {}),
      });
    } else {
      CkMessage({
        type: "error",
        text: "Error al crear cita.",
        ...(loadingMessageKey ? { messageToDestroy: loadingMessageKey } : {}),
      });
    }
  };

  async function handleConfirm(evt: any) {
    const defaultAppointmentDuration: number = 1;
    form
      // @ts-ignore
      .validateFields()
      .then(async (values: any) => {
        // setProcessing(true);
        const loadingMessageKey = CkMessage({
          type: "loading",
          text: "Creando cita...",
        });

        let dateTime: moment.Moment = values.date;
        let hours = values.datetime.split(" ")[0];
        let minute = values.datetime.split(" ")[1];
        dateTime.set({ hour: hours, minute: minute });

        const vehicleInfo: VehicleData = {
          Nickname: `${values.vehicle.brandCode.toUpperCase()} - ${values.vehicle.modelCode.toUpperCase()}`,
          TypeCode: values.vehicle.typeCode,
          TypeName: values.vehicle.typeCode,
          BrandCode: isCustomBrand ? customBrand : values.vehicle.brandCode,
          BrandName: isCustomBrand ? customBrand : values.vehicle.brandCode,
          IsCustomBrand: isCustomBrand,
          YearCode: values.vehicle.yearCode,
          Year: values.vehicle.yearCode,
          ModelCode: isCustomModel
            ? values.vehicle.customModelCode
            : values.vehicle.modelCode,
          ModelName: isCustomModel
            ? values.vehicle.customModelCode
            : values.vehicle.modelCode,
          FuelTypeCode: values.vehicle.fuelTypeCode || "",
          FuelTypeName: values.vehicle.fuelTypeCode || "",
          IsCustomModel: isCustomBrand ? true : isCustomModel,
          IsEnabled: true,
          Km: values.vehicle.km ? values.vehicle.km.toString() : "",
          LicensePlate: values.vehicle.licensePlate
            ? values.vehicle.licensePlate
            : "",
          VehicleId: null,
        };

        if (isCustomBrand) {
          vehicleInfo.Nickname = `${customBrand.toUpperCase()} - ${values.vehicle.modelCode.toUpperCase()}`;
        }

        const customServices = [];
        const serviceCases = [];
        if (values.service["service-type"] === "REP") {
          customServices.push({
            workshopServiceId: values.service["service-repair"],
            serviceTiers: null
          });
          (values.service["service-cases"] || []).forEach((element: WorkshopServiceCase) => {
            serviceCases.push({
              workshopCustomServicesCaseId: element.workshopCustomServicesCaseId,
            });
          });
        }

        if (values.service["service-type"] === "MAN") {
          customServices.push({
            workshopServiceId: values.service["service-maintenance"],
            serviceTiers: [
              {
                servicePackageTypeCode: values.service["service-maintenance-tier"],
              }
            ]
          });
        }

        let toAdd: any = {
          id: "0",
          detail: (values.detail ? values.detail : "") as string,
          statusCode: "APPO",
          startDateTime: dateTime.toISOString() as string,
          endDateTime: dateTime
            .add(defaultAppointmentDuration, "hours")
            .toISOString() as string,
          workshopUserId: user?.id as string,
          endConsumer: {
            name: values.endConsumer.name as string,
            lastName: values.endConsumer.lastName,
            phone: values.endConsumer.phone as string,
            email: values.endConsumer.email as string,
          },
          vehicle: vehicleInfo,
          customServices: customServices,
          cases: serviceCases
        };

        const date = Math.floor(new Date().getTimezoneOffset() / 60);
        const sign = date > 0 ? "-" : "+";
        const zone = sign + date;

        const position = (await getCurrentPosition({})) as IPositionResponse;

        dispatch(
          addAppointment({
            appointment: toAdd,
            workshopId: workshopId,
            localTimeDifference: zone,
            coordinates: `${position.coordinates.latitude},${position.coordinates.longitude}`,
          })
        )
          .then(async (appointmentCreated: any) => {
            // GOOGLE TAG
            // setProcessing(false);
            CkMessage({
              type: "success",
              text: "Cita creada exitosamente.",
              messageToDestroy: loadingMessageKey,
            });
            if (appointmentCreated.error) {
              setProcessing(false);
              handleErrorsForConfirm(
                appointmentCreated.error.message,
                loadingMessageKey
              );
            } else {
              // @ts-ignore
              let allServString: string[] = [];
              let servCodeList: string[] = [];
              if (criteria !== "packages") {
                allServString = selectedFailures.map((item) => {
                  return `${item?.serviceCategoryName}${item.serviceName}-${item.serviceCategoryCode}`;
                });
                servCodeList = selectedFailures.map((item) => {
                  return item.serviceCategoryCode;
                });
              }
              gtmAddLayer({
                appointment_ID: appointmentCreated.payload.data.id,
                service_types:
                  criteria !== "packages" ? allServString.join(",") : null,
                workshop_ID: workshopId,
                event: "add_appointment",
              });

              if (
                servCodeList.includes("FUELINJ") ||
                servCodeList.includes("SPAOILF") ||
                servCodeList.includes("OILCMUL") ||
                servCodeList.includes("FLUIDLVL") ||
                servCodeList.includes("HFENADJ") ||
                appointmentCreated.payload.data.packages?.length > 0
              ) {
                let servicesCodeArray: string = "";
                let servicesNameArray: string = "";
                let servicesPackageList: string = "";

                let serv: Array<any> =
                  appointmentCreated.payload.data.serviceTypes.map(
                    (service: WorkshopService) => service.serviceTypeCode
                  );
                let servName: Array<any> =
                  appointmentCreated.payload.data.serviceTypes.map(
                    (service: WorkshopService) => service.serviceTypeName
                  );

                let packs: Array<any> =
                  appointmentCreated.payload.data.packages.map(
                    (pack: any) => pack.servicePackageCode
                  );

                serv.map((service: string, index: number) => {
                  index > 0
                    ? (servicesCodeArray = servicesCodeArray + "," + service)
                    : (servicesCodeArray = service);
                });
                servName.map((service: string, index: number) => {
                  index > 0
                    ? (servicesNameArray = servicesNameArray + "," + service)
                    : (servicesNameArray = service);
                });
                packs.map((pack, index: number) => {
                  index > 0
                    ? (servicesPackageList = servicesPackageList + "," + pack)
                    : (servicesPackageList = pack);
                });

                // Show recommendations
                const getRecommendations = await getProductRecommendations(
                  appointmentCreated.payload.data.vehicle.vehicleId,
                  servicesCodeArray,
                  appointmentCreated.payload.data.endConsumer.id,
                  servicesPackageList
                );
                setServicesForRec(servicesNameArray);
                setRecommendedProduct(getRecommendations.data);
                setRecProductQuantity(getRecommendations.data.length);
                if (getRecommendations.data.length > 0) {
                  setShowRecProduct(true);
                } else {
                  history.push("/citas");
                  // history.go(0);
                }
              } else {
                history.push("/citas");
                // history.go(0);
              }
              setProcessing(false);
            }
          })
          .catch((err: any) => {
            setProcessing(false);
            CkMessage({ type: "error", text: "Error al crear cuenta" });
          });
      })
      .catch((err: any) => {
        console.info("err: ", err);
      });
  }

  const getClosedDays = () => {
    let closedDaysArray: number[] = [];
    const closedDays = hours.filter((day) => !day.isOpen);
    closedDays?.forEach((day) => {
      closedDaysArray.push(day.dayCode === 7 ? 0 : day.dayCode);
    });
    return closedDaysArray;
  };
  const getWorkingHours = () => {
    let workingHours: any[] = hours.map((day) => {
      const initialTime = moment(day.openTime, "HH:mmZZ").format("HH:mm");
      const closeTime = moment(day.closeTime, "HH:mmZZ").format("HH:mm");

      let intInitialTime = parseInt(initialTime.split(":")[0]);
      let initialMinutes = initialTime.split(":")[1];
      const intCloseTime = parseInt(closeTime.split(":")[0]);
      const closeMinutes = closeTime.split(":")[1];
      const tempArr: string[] = [];
      if (intInitialTime) {
        while (intInitialTime !== intCloseTime) {
          tempArr.push(intInitialTime.toString() + ":" + initialMinutes);
          if (initialMinutes === "00") {
            initialMinutes = "30";
          } else {
            initialMinutes = "00";
            intInitialTime += 1;
          }
          if (intInitialTime === 24) {
            intInitialTime = 0;
          }
        }

        if (initialMinutes !== closeMinutes)
          tempArr.push(intInitialTime.toString() + ":" + initialMinutes);
        tempArr.push(intCloseTime.toString() + ":" + closeMinutes);
      }
      return { dayCode: day.dayCode, workingHours: tempArr };
    });
    setWorkingHours(workingHours);
  };

  const handleHours = () => {
    // @ts-ignore
    form.resetFields(["datetime"]);
    if (workingHours.length > 0) {
      // @ts-ignore
      form.resetFields(["datetime"]);
      // @ts-ignore
      let day = moment(form.getFieldsValue().date).day();
      if (day === 0) day = 7;

      if (day) {
        let aHours: any = workingHours.find(
          (value) => value.dayCode === day
        ).workingHours;
        let hoursCopy = [...aHours];
        hoursCopy.pop();
        hoursCopy.pop();
        setAvailableHours(hoursCopy);
      } else {
        setAvailableHours([]);
      }
    }
  };

  const getDataVehicleBrands = (selected: string) => {
    setVehicleCategorySelected(selected);
    const otherBrand: any = { code: "custom_brand", desc: "Otra marca" };
    CatalogAPI.fetchVehicleBrands(selected).then((values) => {
      let allBrands = values.data;
      if (allBrands.filter((x) => x.code === "custom_brand")?.length === 0)
        allBrands.push(otherBrand);
      setVehicleBrands(allBrands);
      //setVehicleBrands(values.data);
      setVehicleYearsN([]);
      setVehicleModels([]);
      setVehicleFuelType([]);
    });
    // @ts-ignore
    form.resetFields([["vehicle", "brandCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "yearCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "modelCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "fuelTypeCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "displacementCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "equipmentCode"]]);
    setCustomBrand("");
  };

  const getDataVehicleModels = (selected: string) => {
    // @ts-ignore
    let brandCode = form.getFieldValue(["vehicle", "brandCode"]);
    const otherModel: VehicleCatalog = {
      code: "custom_model",
      desc: "Otro modelo",
    };
    // @ts-ignore
    form.resetFields([["vehicle", "modelCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "fuelTypeCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "displacementCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "equipmentCode"]]);
    CatalogAPI.fetchVehicleModels(
      vehicleCategorySelected,
      selected,
      brandCode
    ).then((values) => {
      let allModels: VehicleCatalog[] = values.data;
      if (allModels.filter((x) => x.code === "custom_model")?.length === 0)
        allModels.push(otherModel);
      setVehicleModels(allModels);
      //setVehicleModels(values.data);
      setVehicleFuelType([]);
    });
  };

  const getDataVehicleBrandsYears = (selected: string) => {
    if (selected.startsWith("custom_brand")) {
      setIsCustomBrand(true);
      setIsCustomModel(false);
      setCustomModel("");
    } else setIsCustomBrand(false);

    CatalogAPI.fetchVehicleBrandYears(vehicleCategorySelected, selected).then(
      (values) => {
        setVehicleYearsN(
          values.data.sort((a, b) => parseInt(b.code) - parseInt(a.code))
        );
        setVehicleModels([]);
        setVehicleFuelType([]);
      }
    );
    // @ts-ignore
    form.resetFields([["vehicle", "yearCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "modelCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "fuelTypeCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "displacementCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "equipmentCode"]]);
    setCustomBrand("");
  };

  const getDataVehicleFuelType = (selected: string) => {
    // @ts-ignore
    let brandCode = form.getFieldValue(["vehicle", "brandCode"]);
    // @ts-ignore
    let yearCode = form.getFieldValue(["vehicle", "yearCode"]);
    // @ts-ignore
    form.resetFields([["vehicle", "fuelTypeCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "displacementCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "equipmentCode"]]);
    if (selected.startsWith("custom_model")) setIsCustomModel(true);
    else setIsCustomModel(false);
    // vehicleTypeCode, vehicleModelCode, vehicleBrandCode, vehicleYearCode
    CatalogAPI.fetchVehicleFuelTypes(
      vehicleCategorySelected,
      selected,
      brandCode,
      yearCode
    ).then((values) => {
      setVehicleFuelType(values.data);
    });
  };

  const getVehicleTypes = async () => {
    try {
      const types = (await CatalogAPI.fetchVehicleTypes()).data;
      setVehicleTypes(types);
    } catch (e) {}
    // @ts-ignore
    form.resetFields([["vehicle", "typeCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "brandCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "yearCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "modelCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "fuelTypeCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "displacementCode"]]);
    // @ts-ignore
    form.resetFields([["vehicle", "equipmentCode"]]);
    setCustomBrand("");
  };

  const onFormChange = async (key: string, info: any) => {
    const allValues =
      await info.forms.appointmentInformationForm.getFieldsValue();
    const haveEmptyValues = Object.keys(allValues).some((key) => {
      // Simple check
      if (key === "date" || key === "datetime") {
        const isEmpty =
          !!allValues[key] === false ||
          (Array.isArray(allValues[key]) && allValues[key].length === 0);
        return isEmpty;
      } else if (key === "service") {
        if (
          (allValues[key]["service-type"] === "REP" ||
            allValues[key]["service-type"] === true ||
            allValues[key]["service-type"] === "MAN" ||
            allValues[key]["service-type"] === false) === false
        ) {
          return true;
        }

        // Check category
        if (!!allValues[key]["service-category"] === false) {
          return true;
        }

        // Check maintenance
        if (
          (allValues[key]["service-type"] === "MAN" ||
            allValues[key]["service-type"] === false) &&
          !!allValues[key]["service-maintenance"] === false
        ) {
          return true;
        }

        // Check tier
        if (
          (allValues[key]["service-type"] === "MAN" ||
            allValues[key]["service-type"] === false) &&
          !!allValues[key]["service-maintenance-tier"] === false
        ) {
          return true;
        }

        // Check repair
        if (
          (allValues[key]["service-type"] === "REP" ||
            allValues[key]["service-type"] === true) &&
          !!allValues[key]["service-repair"] === false
        ) {
          return true;
        }

        // Check cases
        const validCases =
          Array.isArray(allValues[key]["service-cases"]) &&
          allValues[key]["service-cases"].length > 0;
        if (
          (allValues[key]["service-type"] === "REP" ||
            allValues[key]["service-type"] === true) &&
          validCases === false &&
          !!allValues[key]["service-case-extra"] === false
        ) {
          return true;
        }
        return false;
      } else {
        // Recursive check
        return Object.keys(allValues[key]).some((recursiveKey) => {
          if (recursiveKey === "comment" || recursiveKey === "warnings") {
            return false;
          }
          const isEmpty =
            allValues[key][recursiveKey] === undefined ||
            allValues[key][recursiveKey] === null ||
            allValues[key][recursiveKey] === "" ||
            (Array.isArray(allValues[key][recursiveKey]) &&
              allValues[key][recursiveKey].length === 0);
          return isEmpty;
        });
      }
    });
    if (haveEmptyValues) {
      setIsFormValid(false);
      return;
    }

    const errors = await info.forms.appointmentInformationForm.getFieldsError();
    if (errors.some((item) => item.errors.length > 0)) {
      setIsFormValid(false);
      return;
    }
    /* const errors  */
    setIsFormValid(true);
  };

  useEffect(() => {
    handleHours();
  }, [workingHours]);

  useEffect(() => {
    getWorkingHours();
    getVehicleTypes();
    setCustomBrand("");
    // @ts-ignore
    form.resetFields(["customBrandName"]);
  }, [hours]);

  useEffect(() => {
    dispatch(setCurrentSection("Nueva cita"));
  }, []);

  return (
    <div className="new-appointment-form" style={{ padding: "1% 4%" }}>
      {/* <h3
        style={{
          color: "var(--orange)",
          textAlign: "left",
          margin: "auto",
          lineHeight: "49px",
          paddingLeft: "2%",
          fontSize: "24px",
        }}
      >
        Nueva Cita
      </h3> */}
      <Form.Provider onFormChange={onFormChange}>
        <AppointmentInformationForm
          form={form}
          availableHours={availableHours}
          handleHours={handleHours}
          getClosedDays={getClosedDays}
          getDataVehicleBrands={getDataVehicleBrands}
          vehicleTypes={vehicleTypes}
          isCustomBrand={isCustomBrand}
          getDataVehicleModels={getDataVehicleModels}
          vehicleYears={vehicleYearsN}
          isCustomModel={isCustomModel}
          getDataVehicleFuelType={getDataVehicleFuelType}
          vehicleModels={vehicleModels}
          getDataVehicleBrandsYears={getDataVehicleBrandsYears}
          vehicleBrands={vehicleBrands}
          setCustomBrand={setCustomBrand}
          vehicleFuelType={vehicleFuelType}
          customModel={customModel}
          setCustomModel={setCustomModel}
        />
        <CustomerInformationForm form={form} />
        <PackageServiceSelection form={form} />
        <Row justify="end" style={{ padding: "15px 2%" }}>
          <Col span={24} className="appointment-buttons">
            <CkButton
              block={false}
              variant="link"
              onClick={() => {
                history.push("/citas");
                history.go(0);
              }}
            >
              Cancelar
            </CkButton>
            <CkButton
              block
              className="new-appoint-button"
              htmlType="submit"
              onClick={handleConfirm}
              disabled={
                isFormValid === false ||
                /* (criteria === "services"
                  ? selectedFailures.length < 1
                  : !selectedPackageTier) || */
                processing
              }
              loading={processing}
            >
              Guardar Cita
            </CkButton>
          </Col>
        </Row>
      </Form.Provider>
      {/* 
      <ProductRecommendationModal
        products={recommendedProduct}
        showOli={showRecProduct}
        onCancel={() => {
          setShowRecProduct(false);
          history.push("/citas");
          history.go(0);
        }}
      /> 
      */}
    </div>
  );
};

export default React.memo(NewAppointmentForm);
