import React, { Fragment, useEffect } from "react";

interface IProps {
  title: string;
  fontSize?: number;
}
const DataDeletionNotice: React.FC<IProps> = ({ title, fontSize}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <p style={{fontWeight: "600", color: "var(--mariner-color)", fontSize: "24px", textAlign: "center"}}>{title}</p>
      <p className="bold" style={{color: "var(--gray-450)", fontSize: fontSize? `${fontSize}px` : "inherit"}}>
        Aviso de privacidad ExxonMobil
      </p>
      <p style={{color: "var(--gray-450)", fontSize: fontSize? `${fontSize}px` : "inherit"}}>
        ExxonMobil México retiene los datos personales todo el tiempo que sea necesario para cumplir con los fines para
        los que fueron recopilados, para ejercer sus derechos legales y para asegurar el cumplimiento de la legislación
        aplicable.
      </p>
      <p style={{color: "var(--gray-450)", fontSize: fontSize? `${fontSize}px` : "inherit"}}>
        Si se da de baja de la plataforma a través de la función de “Borrar Datos”, encontrada en la sección de Perfil,
        los datos de correo, nombres, teléfono y código postal son eliminados del sistema. Solo se mantendrán los datos
        sobre el historial de citas ya que son necesarios para la trazabilidad de citas de los talleres.
      </p>
      <p style={{color: "var(--gray-450)", fontSize: fontSize? `${fontSize}px` : "inherit"}}>
        Usted como titular de los Datos Personales podrá solicitar su cancelación cuando ya no sea necesario
        mantenerlos. Para hacer esta solicitud, contactar a:
      </p>
      <a href="mailto:data.privacy.office@exxonmobil.com">
        <p className="bold" style={{color: "var(--mariner-color)", textDecoration: "underline"}}>
          data.privacy.office@exxonmobil.com
        </p>
      </a>

    </Fragment>
  );
};

export default DataDeletionNotice;
